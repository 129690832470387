import React, { FC, useState, useCallback, useRef } from "react";
import { useQuery } from "@apollo/client";
import { createStyles, makeStyles } from "@mui/styles";
import { Container, Divider, Grid, Theme, useTheme } from "@mui/material";
import clsx from "clsx";
import RHLogo from "@RHCommerceDev/component-rh-logo";
import {
  queryCatalogNavigation,
  querySiteNavigation
} from "@RHCommerceDev/graphql-client/queries/navigation";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { useLocation } from "react-router";
import CatalogNavigation from "@RHCommerceDev/container-inline-header/CatalogNavigation";
import OtherNavigation from "@RHCommerceDev/container-inline-header/OtherNavigation";
import SiteNavigation from "@RHCommerceDev/container-inline-header/SiteNavigation";
import {
  InlineHeaderContentProps,
  InlineHeaderProps
} from "@RHCommerceDev/container-inline-header/types";
import memoize from "@RHCommerceDev/utils/memoize";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useSite from "@RHCommerceDev/hooks/useSite";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import useScrollYoffset from "@RHCommerceDev/hooks/useScrollYoffset";
import RHShippingToPopper from "@RHCommerceDev/component-rh-shipping-to-popper";
import yn from "yn";
import { RHShippingButton } from "@RHCommerceDev/component-rh-shipping-button";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useCartProjectionAtomValue } from "@RHCommerceDev/hooks/atoms";

const env = useEnv();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar,
      width: "100%",
      resize: "both",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6)
      }
    },
    container: {
      paddingTop: "20px",
      paddingBottom: "10px",
      width: "100%",
      maxWidth: "1920px",
      "& a": {
        display: "block"
      }
    },
    absolute: {
      position: "absolute"
    },
    relative: {
      position: "relative"
    },
    navTop: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "-4px"
    },
    logoContainer: {
      paddingBottom: "13px"
    },
    navBottomContainer: {
      paddingTop: theme.spacing(1)
    },
    logo: {
      marginRight: 48,
      transition: "all 0.25s ease-in-out"
    },
    globalNavigation: {
      display: "flex",
      justifyContent: "flex-end"
    },
    shippingMenuWrapper: {
      textAlign: "end"
    }
  })
);

export const InlineHeaderContent: FC<InlineHeaderContentProps> = ({
  styles,
  LogoProps,
  SiteNavigationProps,
  OtherNavigationProps,
  CatalogNavigationProps
}) => {
  const theme = useTheme();
  const location = useLocation();
  const brand = useBrand();
  const isModernBrand = brand === "MO";
  const classes = useStyles();
  const shippingButtonRef = useRef<HTMLButtonElement>(null);
  const scrollYoffset = useScrollYoffset();
  const colorPaths = ["/", "/swatch/order.jsp"];
  const logoPrimaryPaths = ["/", "/swatch/order.jsp"];
  const lightTheme =
    isModernBrand || styles?.colorStyle === "light"
      ? false
      : !colorPaths.includes(location.pathname);
  const isPrimary =
    isModernBrand || logoPrimaryPaths.includes(location.pathname);

  const customLogoColors: { [key: string]: string } = {
    TN: "Black"
  };

  const FEATURE_SUPPRESS_UK = yn(env?.FEATURE_SUPPRESS_UK);

  const [open, setOpen] = useState(false);

  const handleShippingOptionsClick = useCallback(() => setOpen(!open), [open]);

  return (
    <nav
      className={clsx({
        [classes.root]: true,
        [classes.absolute]: colorPaths.includes(location.pathname),
        [classes.relative]: !colorPaths.includes(location.pathname)
      })}
      style={{
        backgroundColor:
          isModernBrand && !colorPaths.includes(location.pathname)
            ? theme.palette.common.black
            : "",
        ...styles?.root
      }}
    >
      <Container
        className={classes.container}
        style={{
          color: lightTheme
            ? theme.palette.common.black
            : theme.palette.common.white
        }}
      >
        <>
          {FEATURE_SUPPRESS_UK ? null : (
            <Grid container className={classes.globalNavigation}>
              <Grid item xs={12} className={classes.shippingMenuWrapper}>
                <RHShippingButton
                  ref={shippingButtonRef}
                  open={open}
                  onClick={handleShippingOptionsClick}
                />
                <RHShippingToPopper
                  isOpen={open}
                  setIsOpen={setOpen}
                  anchorEl={shippingButtonRef.current}
                />
              </Grid>
            </Grid>
          )}
          <div className={classes.navTop} data-analytics-id="topNav">
            <div className={classes.logoContainer}>
              <RHLogo
                brand={brand}
                primary={isPrimary}
                customColor={customLogoColors?.[brand]}
                {...LogoProps}
              />
            </div>
            <SiteNavigation {...SiteNavigationProps} />
            <OtherNavigation
              menuDarkTheme={isModernBrand}
              {...OtherNavigationProps}
            />
          </div>
          <Divider
            style={{
              backgroundColor: isModernBrand
                ? theme.palette.common.white
                : !lightTheme
                ? theme.palette.common.white
                : theme.palette.common.black,
              opacity: isModernBrand ? 0.4 : !lightTheme ? 0.4 : 0.2,
              ...styles?.divider
            }}
          />
          <section className={classes.navBottomContainer}>
            <CatalogNavigation
              darkTheme={!lightTheme}
              menuDarkTheme={isModernBrand}
              {...CatalogNavigationProps}
            />
          </section>
        </>
      </Container>
    </nav>
  );
};

const IHC = memoize(InlineHeaderContent);

InlineHeaderContent.defaultProps = {};

const InlineHeader: FC<InlineHeaderProps> = memoize(
  ({ InlineHeaderContentProps }) => {
    // const nextgenPg =" useIsoCookies(["nextgenpg"])?.nextgenpg";
    //defaulting it to NG
    const nextgenPg = "true";
    const siteId = useSite();
    const cartProjection = useCartProjectionAtomValue();
    const locale = useLocale();
    const countryCode = useCountry();

    const { data: { catalogNavigation } = {} as Query, refetch } =
      useQuery<Query>(queryCatalogNavigation, {
        variables: {
          siteId,
          filters: "",
          mobile: false,
          countryCode,
          nextgenPg: nextgenPg || "false"
        }
      });

    const { data: { siteNavigation } = {} as Query } = useQuery<Query>(
      querySiteNavigation,
      {
        variables: {
          siteId,
          locale
        }
      }
    );

    useDidMountEffect(() => {
      refetch({
        siteId
      });
    }, [siteId]);

    const location = useLocation();

    return (
      <IHC
        SiteNavigationProps={{
          siteNavigation,
          ...InlineHeaderContentProps?.SiteNavigationProps
        }}
        OtherNavigationProps={{
          cartProjection,
          ...InlineHeaderContentProps?.OtherNavigationProps
        }}
        CatalogNavigationProps={{
          catalogNavigation,
          ...InlineHeaderContentProps?.CatalogNavigationProps
        }}
        LogoProps={InlineHeaderContentProps?.LogoProps}
        styles={InlineHeaderContentProps?.styles}
      />
    );
  }
);

export default InlineHeader;
