import { useQuery } from "@apollo/client";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import RHRBabyChildIcon from "@RHCommerceDev/icon-babychild-rhr";
import RHROutdoorIcon from "@RHCommerceDev/icon-outdoor-rhr";
import RHRTeenIcon from "@RHCommerceDev/icon-teen-rhr";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import clsx from "clsx";
import AnimatedHamburgerIcon from "@RHCommerceDev/component-animated-hamburger-icon";
import Drawer from "@RHCommerceDev/component-drawer";
import Promo from "@RHCommerceDev/component-promo";
import GlobalCountrySelector from "@RHCommerceDev/component-global-country-selector";
import RHLink from "@RHCommerceDev/component-rh-link";
import CountryNavMenu from "@RHCommerceDev/container-rhr-header/CountryNavMenu";
import MobileMenuContent from "@RHCommerceDev/container-rhr-header/MobileMenuContent";
import CatalogNavigation from "@RHCommerceDev/container-rhr-header/RHRCatalogNavigation";
import {
  RHRHeaderContentProps,
  RHRHeaderProps
} from "@RHCommerceDev/container-rhr-header/types";
import RHRSearchField from "@RHCommerceDev/container-rhr-search-field";
import {
  usePageContent,
  usePageContentNoProxy
} from "@RHCommerceDev/custom-providers/LocationProvider";
import {
  queryCatalogNavigation,
  queryWORHMenu
} from "@RHCommerceDev/graphql-client/queries/navigation";
import { queryPromoDisplayContent } from "@RHCommerceDev/graphql-client/queries/promo-display-content";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import { useCartProjectionAtomValue } from "@RHCommerceDev/hooks/atoms";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import useIsHomePage from "@RHCommerceDev/hooks/useIsHomePage";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { useMousePosition } from "@RHCommerceDev/hooks/useMousePosition";
import useSite from "@RHCommerceDev/hooks/useSite";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useState from "@RHCommerceDev/hooks/useState";
import RHRCartIcon from "@RHCommerceDev/icon-cart-rhr";
import RHAEMIcon from "@RHCommerceDev/icon-aem";
import RHRAccountIcon from "@RHCommerceDev/icon-account-rhr";
import WORHIcon from "@RHCommerceDev/icon-worh";
import cloneDeep from "lodash.clonedeep";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ITEMS_IN_CART } from "@RHCommerceDev/resources/rh-header.json";
import {
  BREAKPOINT_MD,
  PAGE_BG_GREY,
  PROMO_ABOVE_NAV,
  RHR_DRAWER_TRANSITION_DURATION,
  RHR_FADE_TIMEOUT_DURATION,
  RHR_HIGHLIGHT_TRANSITION_DURATION,
  WORH_BOTTOM_LINKS_CATEGORY_ID
} from "@RHCommerceDev/utils/constants";
import { createStyles, makeStyles } from "@mui/styles";
import {
  AppBar,
  Fade,
  Theme,
  Toolbar,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import memoize from "@RHCommerceDev/utils/memoize";
import { prefixTargetUrlsWithLocalization } from "@RHCommerceDev/utils/prefixTargetUrlsWithLocalization";
import { bgGreyHiddenRegex } from "@RHCommerceDev/utils/regex";
import yn from "yn";
import loadable from "@loadable/component";
import { getRhrHeaderHeight } from "@RHCommerceDev/utils/getRhrHeaderHeight";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import { useAppId } from "hooks/useAppId";

const AccountNavMenu = loadable(
  () =>
    import(
      /* webpackChunkName: "container-rhr-header/AccountNavMenu" */ "@RHCommerceDev/container-rhr-header/AccountNavMenu"
    ),
  {
    ssr: false,
    fallback: <RHRAccountIcon className="h-4 w-4 xsm:h-6 xsm:w-6" />
  }
);

import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
const WORHMenuContent = loadable(
  () =>
    import(
      /* webpackChunkName: "container-rhr-header/WORHMenuContent" */ "@RHCommerceDev/container-rhr-header/WORHMenuContent"
    ),
  { fallback: <></> }
);

const BLACK = "Black";
const WHITE = "White";
const BUFF_COLOR = "#f9f7f4";
const STATIC = "static";
const RHTN = "rhteen";
const RHBC = "rhbabyandchild";
const RHOD = "rhoutdoor";
const env = useEnv();
const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      "@media print": {
        display: "none"
      },
      height: 175,
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        height: 109
      },
      background:
        "linear-gradient(0deg, rgba(38, 22, 10, 0.00) 0%, #26160A 100%)",
      backgroundBlendMode: "multiply",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "1px"
      }
    },
    catalogNavigationContainer: {
      position: "relative",
      paddingLeft: "0 40px",
      padding: theme.spacing(0, 5, 0),
      zIndex: theme.zIndex.appBar,
      transition: `background-color ${RHR_HIGHLIGHT_TRANSITION_DURATION}ms ease-in-out`,
      [theme.breakpoints.up("md")]: {
        /* to make the 7th element  of 13 to be centre aligned below the logo the below logic is written */
        "& >div >div:nth-child(-n+6)": {
          //decides the logic for elemnts before the middle element from 0 to 6
          marginRight: undefined
        },
        "& >div >div:nth-last-child(1)": {
          //no margin so that last element stays at the end
          marginRight: 0
        }
      },
      [theme.breakpoints.up("lg")]: {
        "& >div >div:nth-child(-n+6)": {
          marginRight: undefined
        },
        "& >div >div:nth-last-child(-n+7)": {
          //spacing for elemnents from middle element to last
          marginRight: undefined
        },

        "& >div >div:nth-last-child(1)": {
          marginRight: 0
        }
      },

      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(0, 10, 0),
        "& >div >div:nth-child(-n+6)": {
          marginRight: undefined
        },
        "& >div >div:nth-last-child(-n+7)": {
          marginRight: undefined
        },

        "& >div >div:nth-last-child(1)": {
          marginRight: 0
        }
      }
    },
    drawerRoot: {
      "& .MuiBackdrop-root": {
        background: "rgba(0, 0, 0, 0.2)"
      }
    },
    mobileLogoWrapper: {
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(3),
      "& >svg": {
        height: theme.spacing(7),
        width: "100%",
        fill: "black !important"
      }
    }
  });
});

export const RHRHeaderContent: FC<RHRHeaderContentProps> = ({
  aboveNavPromoDisplay,
  CatalogNavigationProps,
  profileStatus,
  styles,
  worhMenu,
  worhMoblieFooterLinks,
  home,
  pagePath = "",
  isAem,
  showLogo,
  showClientTools,
  showShoppingCategories,
  aemLogoXS,
  aemLogoViewboxXS,
  aemLogoWidthXS,
  aemLogoHeightXS,
  aemLogoFillXS,
  aemLogoSM,
  aemLogoViewboxSM,
  aemLogoFillSM,
  aemLogoWidthSM,
  aemLogoHeightSM,
  aemLogoMD,
  aemLogoViewboxMD,
  aemLogoWidthMD,
  aemLogoHeightMD,
  aemLogoFillMD,
  aemLogoLG,
  aemLogoViewboxLG,
  aemLogoFillLG,
  aemLogoWidthLG,
  aemLogoHeightLG,
  aemLogoXL,
  aemLogoViewboxXL,
  aemLogoFillXL,
  aemLogoWidthXL,
  aemLogoHeightXL,
  aemHamburgerNavItems,
  aemFooterLinks,
  isModalTrigger,
  aemLogoLink,
  catalogNavigation,
  backtoRHLabel,
  backToLink,
  showBackToRH,
  renderingFrom
}) => {
  const theme = useTheme();
  const isHomePage = useIsHomePage();
  const { pageContent } = usePageContent();
  const [isWORHMenuOpen, setIsWORHMenuOpen] = useState(false);
  const [_anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
  const [selectedCatalogNavItem, setSelectedCatalogNavItem] =
    useState<NavigationElement | null>(null);
  const { pathname } = useLocation();
  const cartPage = pathname.includes("shopping_cart") || false;
  const env = useEnv();
  const FEATURE_COUNTRY_NAV_MENU = yn(env?.FEATURE_COUNTRY_NAV_MENU);
  const { params } = useParams2({ newPdpLayout: false });
  const newPdpLayout = yn(params?.newPdpLayout);
  const FEATURE_BCT_SUNSET = yn(env?.FEATURE_BCT_SUNSET);
  const hostname = env.REACT_APP_ATG_HOST_NAME;
  const { isConcierge } = useAppId();

  const req = getReqContext();
  const userAgent = req && req?.headers["user-agent"];
  const isMobile = useCallback(() => {
    const userAgent = !processEnvServer
      ? typeof window.navigator === "undefined"
        ? ""
        : navigator.userAgent
      : "";
    return /iPhone|iPod|Android/i.test(userAgent);
  }, []);
  const RHTNEndpoint = useMemo(
    () => (FEATURE_BCT_SUNSET ? "teen" : RHTN),
    [FEATURE_BCT_SUNSET, RHTN]
  );
  const RHBCEndpoint = useMemo(
    () => (FEATURE_BCT_SUNSET ? "babyandchild" : RHBC),
    [FEATURE_BCT_SUNSET, RHBC]
  );
  const isMobileDevice = useMemo(() => {
    if (processEnvServer) {
      if (userAgent) {
        const { isMobile } = getSelectorsByUserAgent(userAgent);
        return isMobile;
      }
    } else {
      return isMobile();
    }
  }, [isMobile, userAgent]);

  const endpoint = useMemo(
    () =>
      pathname.includes("babyandchild")
        ? RHBCEndpoint
        : pathname.includes("teen")
        ? RHTNEndpoint
        : "",
    [RHBCEndpoint, pathname, RHTNEndpoint]
  );
  let mobile = false;
  const userAgentSSR = req && req?.headers["user-agent"];
  if (userAgentSSR) {
    const { isMobile } = getSelectorsByUserAgent(userAgentSSR);
    mobile = !!isMobile;
  }
  const prefix = useLocalization();
  const brand = useSite();
  const conciergeRHHref = `https://${env.REACT_APP_ATG_HOST_NAME}${prefix}`;

  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"), {
    noSsr: !processEnvServer
  });
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"), {
    noSsr: !processEnvServer
  });
  const xs = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"));
  const sm = useMediaQuery<Theme>(theme => theme.breakpoints.only("sm"));
  const md = useMediaQuery<Theme>(theme => theme.breakpoints.only("md"));
  const lg = useMediaQuery<Theme>(theme => theme.breakpoints.only("lg"));
  const xl = useMediaQuery<Theme>(theme => theme.breakpoints.only("xl"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const appBarRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const [isHighlighted, setIsHighlighted] = useState(false);
  const mousePosition = useMousePosition({
    doNotTrack: !mdUp || styles?.root?.background !== "transparent"
  });

  const cartProjection = useCartProjectionAtomValue();
  const cartBrokerEnabled = yn(env.FEATURE_PDP_CART_BROKER);
  const bgGreyHiddenPaths =
    bgGreyHiddenRegex?.test(pagePath) &&
    !/^\/checkout\/shopping_cart\.jsp/.test(pagePath);

  const quantity = cartBrokerEnabled
    ? cartProjection?.totalItemQuantity
    : profileStatus?.cartCount;

  const isDrawerOpen = useMemo(
    () =>
      processEnvServer ? false : isWORHMenuOpen || !!selectedCatalogNavItem,
    [isWORHMenuOpen, selectedCatalogNavItem]
  );

  const shouldHideBackgroundOnPDP =
    newPdpLayout && pathname?.includes("product.jsp");

  const aboveNavBannerHeight = useMemo(() => {
    if (processEnvServer) {
      return 0;
    }
    const aboveNavBannerElem = document.querySelector(
      "#aboveNavBanner"
    ) as HTMLElement | null;
    const aboveNavBannerWrapperElem = aboveNavBannerElem?.closest(
      '[data-banner="above-nav"]'
    ) as HTMLElement | null;
    const mainElem = document.querySelector("#main") as HTMLElement | null;
    if (aboveNavBannerElem) {
      const aboveNavBannerHeight = aboveNavBannerElem.offsetHeight;
      const fixAboveNav =
        (isHomePage && (brand === "RH" || brand === "RHUK")) || isDrawerOpen;
      if (aboveNavBannerWrapperElem) {
        aboveNavBannerWrapperElem.style.position = fixAboveNav
          ? "fixed"
          : "relative";
        aboveNavBannerWrapperElem.style.zIndex =
          fixAboveNav || shouldHideBackgroundOnPDP ? "1101" : "2";
        aboveNavBannerWrapperElem.style.width = "100%";
      }
      if (mainElem) {
        mainElem.style.paddingTop = fixAboveNav
          ? `${aboveNavBannerHeight}px`
          : "0";
      }
      return aboveNavBannerHeight;
    }
    return 0;
  }, [isDrawerOpen]);

  const { isDarkMode, color, background, position } = useMemo(() => {
    let isDarkMode = isHighlighted ? false : styles?.root?.color !== "Black";
    let color = isHighlighted
      ? theme.palette.common.black
      : styles?.root?.color;

    let background =
      mdUp && isHighlighted
        ? isDarkMode
          ? theme.palette.common.black
          : BUFF_COLOR
        : bgGreyHiddenPaths
        ? theme.palette.common.white
        : styles?.root?.background === "White Gradient"
        ? "linear-gradient(0deg, rgba(38, 22, 10, 0.00) 0%, #26160A 100%)"
        : styles?.root?.background;

    const position = styles?.root?.position;

    color = smDown
      ? isHomePage && (brand === "RH" || brand === "RHUK")
        ? theme.palette.common.white
        : styles?.root?.color ||
          (isDarkMode ? theme.palette.common.white : theme.palette.common.black)
      : isHighlighted
      ? theme.palette.common.black
      : styles?.root?.color;

    isDarkMode = smDown
      ? isHomePage
      : isHighlighted
      ? false
      : styles?.root?.color !== "Black";

    return {
      isDarkMode,
      color,
      background,
      position
    };
  }, [
    isHighlighted,
    styles,
    theme.palette.common.black,
    theme.palette.common.white,
    theme.palette.background.default,
    bgGreyHiddenPaths,
    smDown,
    xs,
    sm,
    md,
    lg,
    xl,
    isHomePage
  ]);

  const classes = useStyles();

  const overriddenStyles: any = {
    root: {
      ...styles?.root,
      background: bgGreyHiddenPaths
        ? theme.palette.common.white
        : styles?.root?.background
    }
  };
  const catalogNavigationData = catalogNavigation
    ? ({
        childCategories: Object.values(catalogNavigation)
      } as NavigationElement)
    : CatalogNavigationProps?.catalogNavigation;

  const highlightNavBar = useCallback(() => {
    if (
      !isHighlighted &&
      background === "transparent" &&
      !isDrawerOpen &&
      mdUp
    ) {
      setIsHighlighted(true);
    }
  }, [isHighlighted, background, isDrawerOpen, mdUp]);

  const unHighlightNavBar = useCallback(() => {
    if (!isDrawerOpen) {
      setIsHighlighted(false);
    }
  }, [isDrawerOpen, setIsHighlighted]);

  const handleToggleHighlight = useCallback(() => {
    if (!isHomePage) return;
    const toolbarHeight = toolbarRef.current?.clientHeight ?? 0;
    const toolbarWidth = toolbarRef.current?.clientWidth ?? 0;

    if (mousePosition.x > toolbarWidth || mousePosition.y > toolbarHeight) {
      return setTimeout(
        () => setIsHighlighted(false),
        RHR_DRAWER_TRANSITION_DURATION
      );
    }

    highlightNavBar();
  }, [highlightNavBar, mousePosition, isHomePage]);

  const handleCategoryDrawerOpen = useCallback(
    (navItem, selectedHTMLElement) => {
      highlightNavBar();
      setIsWORHMenuOpen(false);
      setSelectedCatalogNavItem(navItem);
      setAnchorRef(selectedHTMLElement);
    },
    [highlightNavBar]
  );

  const handleCategoryDrawerClose = useCallback(() => {
    setSelectedCatalogNavItem(null);
    setAnchorRef(null);
    handleToggleHighlight();
  }, [setSelectedCatalogNavItem, handleToggleHighlight]);

  const handleHamburgerClick = useCallback(
    e => {
      if (isModalTrigger) return false;
      const target = e.target;
      const id = target?.id;
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { remove: "analytics-catalog" }
        )
      );
      if (!isWORHMenuOpen) {
        document?.body?.dispatchEvent(
          new CustomEvent("cta_click", {
            detail: {
              item: {
                isHamburgerMenuOpened: true,
                iconName: "Hamburger Icon",
                navigationName: "Navigation/HamburgerMenu",
                class: target?.className,
                id: target?.id || id
              }
            }
          })
        );
      }
      if (!!selectedCatalogNavItem) {
        handleCategoryDrawerClose();
      }
      highlightNavBar();
      setIsWORHMenuOpen(isOpen => !isOpen);
    },
    [
      selectedCatalogNavItem,
      isWORHMenuOpen,
      handleCategoryDrawerClose,
      highlightNavBar
    ]
  );
  const appBarHeight = getRhrHeaderHeight(cartPage, "default");
  const xsAppBarHeight = getRhrHeaderHeight(cartPage, "xs");

  const handleWORHDrawerClose = useCallback(() => {
    setIsWORHMenuOpen(false);
    mdUp ? handleToggleHighlight() : unHighlightNavBar();
  }, [handleToggleHighlight, unHighlightNavBar, mdUp]);

  const headerIconStyle = {
    color,
    height: undefined
  };

  const BreakpointAemLogo = ({ color }) => (
    <React.Fragment>
      {xs && (
        <RHAEMIcon
          icon={aemLogoXS || ""}
          viewBox={aemLogoViewboxXS}
          fill={color || aemLogoFillXS}
          width={aemLogoWidthXS}
          height={aemLogoHeightXS}
        />
      )}
      {sm && (
        <RHAEMIcon
          icon={aemLogoSM || ""}
          viewBox={aemLogoViewboxSM}
          fill={color || aemLogoFillSM}
          width={aemLogoWidthSM}
          height={aemLogoHeightSM}
        />
      )}
      {md && (
        <RHAEMIcon
          icon={aemLogoMD || ""}
          viewBox={aemLogoViewboxMD}
          fill={color || aemLogoFillMD}
          width={aemLogoWidthMD}
          height={aemLogoHeightMD}
        />
      )}
      {lg && (
        <RHAEMIcon
          icon={aemLogoLG || ""}
          viewBox={aemLogoViewboxLG}
          fill={color || aemLogoFillLG}
          width={aemLogoWidthLG}
          height={aemLogoHeightLG}
        />
      )}
      {xl && (
        <RHAEMIcon
          icon={aemLogoXL || ""}
          viewBox={aemLogoViewboxXL}
          fill={color || aemLogoFillXL}
          width={aemLogoWidthXL}
          height={aemLogoHeightXL}
        />
      )}
    </React.Fragment>
  );

  const handleSelectedNavItem = useCallback((navItem, selectedHTMLElement) => {
    handleCategoryDrawerOpen(navItem, selectedHTMLElement);
  }, []);

  const isTailwindComponentEnabled = useTailwindComponent();
  const Typography = isTailwindComponentEnabled
    ? TailwindTypography
    : MuiTypography;

  useEffect(() => {
    const handleScroll = () => {
      if (isWORHMenuOpen) {
        setIsWORHMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isWORHMenuOpen]);

  const getRHLogoURL = useCallback(
    (urlEndpoint: string, endpointFor: string) => {
      if (endpointFor === "BCT") {
        return FEATURE_BCT_SUNSET
          ? `https://${hostname}${prefix}/${urlEndpoint}`
          : `https://${urlEndpoint}.${hostname}${prefix}`;
      } else {
        return FEATURE_BCT_SUNSET
          ? `https://${hostname}${prefix}/${urlEndpoint}`
          : `https://${hostname}${prefix}`;
      }
    },
    [FEATURE_BCT_SUNSET, hostname, prefix]
  );

  return (
    <>
      {position !== "absolute" && position !== "fixed" && (
        <div
          className={`${classes.appBar}`}
          style={{
            height: xs ? xsAppBarHeight : appBarHeight
          }}
        />
      )}

      <AppBar
        id="app-navigation-bar"
        data-testid="rhr-header"
        ref={appBarRef}
        className={`${clsx(classes?.appBar)} header-height ${
          yn(env.FEATURE_STYTCH) ? "!pr-0" : ""
        }`} // added this padding right, as the nav was shifting TD-95
        style={{
          ...overriddenStyles?.root,
          color,
          transition: shouldHideBackgroundOnPDP
            ? "unset"
            : "background 0.8s ease-in-out",
          background:
            isHomePage && (brand === "RH" || brand === "RHUK")
              ? isDarkMode
                ? "linear-gradient(0deg, rgba(38, 22, 10, 0.00) 0%, #26160A 100%)"
                : BUFF_COLOR
              : background ?? BUFF_COLOR,

          position: isDrawerOpen ? "fixed" : "absolute",
          top:
            !isDrawerOpen && !aboveNavPromoDisplay ? 0 : aboveNavBannerHeight,
          height: xs ? xsAppBarHeight : appBarHeight,
          ...(shouldHideBackgroundOnPDP && {
            background: isWORHMenuOpen ? "#f9f7f4" : "transparent"
          })
        }}
      >
        <Toolbar
          ref={toolbarRef}
          className="p-0 z-[1100] md:h-[132px] !md:min-h-[132px] sm:h-[124px] sm:m-h-[124px]"
          style={{ minHeight: "auto" }}
          onMouseEnter={highlightNavBar}
          onMouseLeave={unHighlightNavBar}
        >
          <div className="flex-auto sm:h-[124px] md:h-[132px]">
            {/* NavBar Top Row */}
            <div
              className={clsx(
                `relative p-4 sm:p-0
                ${
                  brand === "RH" ? "customXs:pb-5" : "customXs:pb-4"
                } pb-[20px] ${"sm:pt-[10px]"} sm:px-[32px] sm:pb-[31px] md:px-[40px] xl:px-[80px]`
              )}
            >
              {aboveNavPromoDisplay
                ?.filter(promo => promo.template)
                ?.map(promo => (
                  <Promo key={promo.id} data={promo} />
                ))}
              {/* search / account / cart  */}
              {(!isMobileDevice || smUp) && (
                <GlobalCountrySelector
                  color={color}
                  isDesktop={smUp}
                  pageContent={pageContent?.globalHeader}
                />
              )}
              <div
                className={`grid h-full w-full grid-cols-3 justify-end items-center

                `}
              >
                {(!isAem || (isAem && showClientTools)) && (
                  <div
                    className={clsx(
                      `${
                        brand === "OD"
                          ? ""
                          : `md:relative md:top-[70%] md:-translate-y-[100%] ${
                              !isTailwindComponentEnabled && "sm:mt-5 md:mt-0"
                            }`
                      }`
                    )}
                  >
                    <div
                      className={clsx(
                        `flex w-full items-center place-self-start lg:gap-12 sm:gap-10 gap-8 ${
                          isTailwindComponentEnabled &&
                          "h-4 sm:h-auto leading-none sm:translate-y-[27%] md:translate-y-[0%]"
                        }`
                      )}
                    >
                      <div className={`flex items-center`}>
                        <AnimatedHamburgerIcon
                          color={color}
                          style={{ padding: 0, height: "100%", width: "100%" }}
                          onClick={handleHamburgerClick}
                          isOpen={isWORHMenuOpen}
                        />
                      </div>
                      <div>
                        <RHRSearchField
                          disabled={isModalTrigger}
                          isDarkMode={isDarkMode}
                          color={color}
                          isHeaderSearch={true}
                          isRHRSearchField={true}
                          onClose={handleCategoryDrawerClose}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isAem && showLogo && (
                  <div>
                    <div
                      className={`flex w-full items-center place-self-center`}
                    >
                      <a
                        id="container-rhr-header_logo"
                        href={
                          aemLogoLink ||
                          `https://${
                            brand === "TN"
                              ? RHTN + "."
                              : brand === "BC"
                              ? RHBC + "."
                              : brand === "OD"
                              ? RHOD + "."
                              : ""
                          }${env.REACT_APP_ATG_HOST_NAME}${prefix}`
                        }
                        data-analytics-worhlogo="worh-logo"
                        style={
                          isModalTrigger ? { pointerEvents: "none" } : undefined
                        }
                      >
                        <BreakpointAemLogo color={color} />
                      </a>
                    </div>
                  </div>
                )}
                {!isAem && (
                  <div>
                    <div
                      className={`flex w-full justify-center items-center place-self-center`}
                    >
                      {brand === "TN" && (
                        <a
                          id="container-rhr-header_logo-TN"
                          data-testid="container-rhr-header_logo-TN"
                          href={
                            isConcierge
                              ? `${conciergeRHHref}`
                              : `${getRHLogoURL(RHTNEndpoint, "BCT")}`
                          }
                          data-analytics-worhlogo="worh-logo"
                          style={
                            isModalTrigger
                              ? { pointerEvents: "none" }
                              : undefined
                          }
                          className={clsx({
                            ["block md:hidden"]: home,
                            ["leading-[0]"]: true
                          })}
                        >
                          <RHRTeenIcon
                            strokeColor={color}
                            fillColor={color}
                            className="xs:min-h-[46px] xs:min-w-12 sm:min-h-[67px] sm:min-w-[70px]"
                          />
                        </a>
                      )}
                      {brand === "BC" && (
                        <a
                          id="container-rhr-header_logo-BC"
                          data-testid="container-rhr-header_logo-BC"
                          href={
                            isConcierge
                              ? `${conciergeRHHref}`
                              : `${getRHLogoURL(RHBCEndpoint, "BCT")}`
                          }
                          data-analytics-worhlogo="worh-logo"
                          style={
                            isModalTrigger
                              ? { pointerEvents: "none" }
                              : undefined
                          }
                          className={clsx({
                            ["block md:hidden"]: home,
                            ["leading-[0]"]: true
                          })}
                        >
                          <RHRBabyChildIcon
                            strokeColor={color}
                            fillColor={color}
                            className="xs:min-h-[46px] xs:min-w-12 sm:min-h-[67px] sm:min-w-[70px]"
                          />
                        </a>
                      )}
                      {brand === "OD" && (
                        <a
                          id="container-rhr-header_logo-OD"
                          href={`https://${RHOD}.${env.REACT_APP_ATG_HOST_NAME}${prefix}`}
                          data-analytics-worhlogo="worh-logo"
                          style={
                            isModalTrigger
                              ? { pointerEvents: "none" }
                              : undefined
                          }
                          className={clsx({
                            ["block md:hidden"]: home,
                            ["leading-[0]"]: true
                          })}
                        >
                          <RHROutdoorIcon
                            strokeColor={color}
                            fillColor={color}
                            style={{ width: "215px", height: "17px" }}
                          />
                        </a>
                      )}
                      {brand !== "TN" && brand !== "BC" && brand !== "OD" && (
                        <a
                          id="container-rhr-header_logo-rhr"
                          href={
                            isConcierge
                              ? `${conciergeRHHref}`
                              : `${getRHLogoURL(endpoint, "RH")}`
                          }
                          data-analytics-worhlogo="worh-logo"
                          style={
                            isModalTrigger
                              ? { pointerEvents: "none" }
                              : undefined
                          }
                          className={clsx({
                            ["block md:hidden"]: home,
                            ["leading-[0]"]: true
                          })}
                          aria-label="The World of RH Homepage"
                        >
                          <WORHIcon
                            fillColor={color}
                            className={
                              "!w-[48px] !h-[46px] sm:!w-[70px] sm:!h-[67px]"
                            }
                          />
                        </a>
                      )}
                    </div>
                  </div>
                )}
                {(!isAem || (isAem && showClientTools)) && (
                  <div
                    className={clsx(
                      `${
                        brand === "OD"
                          ? ""
                          : `md:relative md:top-[70%] md:-translate-y-[150%] ${
                              !isTailwindComponentEnabled && "sm:mt-5 md:mt-0"
                            }`
                      }`
                    )}
                  >
                    <div
                      className={`flex w-full justify-end items-center place-self-end md:min-h-[26px]`}
                    >
                      <div
                        className={`h-full w-full flex justify-end lg:gap-12 sm:gap-10 gap-8 ${
                          isTailwindComponentEnabled &&
                          "sm:translate-y-[50%] md:translate-y-[0%]"
                        }`}
                      >
                        {FEATURE_COUNTRY_NAV_MENU ? (
                          <CountryNavMenu
                            open={isHighlighted}
                            style={headerIconStyle}
                            onClose={handleToggleHighlight}
                          />
                        ) : null}
                        {!isModalTrigger && (
                          <AccountNavMenu
                            style={{ color }}
                            rhrAccountIcon={true}
                            darkTheme={isDarkMode}
                            onClose={handleToggleHighlight}
                            className="flex"
                          />
                        )}
                        {isModalTrigger && (
                          <RHRAccountIcon
                            className={`!w-[16px] !h-[16px] sm:!w-6 sm:!h-6`}
                          />
                        )}
                        {!isModalTrigger && (
                          <RHLink
                            component={RouterLink}
                            id="container-rhrHeader_cart-btn"
                            to={`${prefix}/checkout/shopping_cart.jsp`}
                            underline="none"
                            className="flex items-end"
                            ctaName={`${quantity} ${
                              pageContent.HEADER_CONTENT.ITEMS_IN_CART ??
                              ITEMS_IN_CART
                            }`}
                          >
                            <div className="flex items-center gap-2">
                              <RHRCartIcon
                                className={clsx(
                                  "h-4 w-4 ",
                                  {
                                    "sm:h-6 sm:w-6": isTailwindComponentEnabled
                                  },
                                  {
                                    "xsm:h-6 xsm:w-6":
                                      !isTailwindComponentEnabled
                                  }
                                )}
                                style={headerIconStyle}
                              />

                              {!!quantity && (
                                <Typography
                                  variant="caption"
                                  className={"!ml-[8px]"}
                                  style={headerIconStyle}
                                >
                                  {quantity}
                                </Typography>
                              )}
                            </div>
                          </RHLink>
                        )}
                        {isModalTrigger && (
                          <div className="flex items-center gap-2">
                            <RHRCartIcon
                              className={
                                "!w-[16px] !h-[16px] sm:!w-[26px] sm:!h-[26px]"
                              }
                              style={headerIconStyle}
                            />
                            {!!quantity && (
                              <Typography variant="caption">
                                {quantity}
                              </Typography>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Catalog Navigation Row */}
            {(!processEnvServer ? mdUp : !mobile) &&
              (!isAem || (isAem && showShoppingCategories)) && (
                <div className={classes?.catalogNavigationContainer}>
                  {!catalogNavigationData ? (
                    <div className="h-[16px]"></div>
                  ) : (
                    <CatalogNavigation
                      {...{ catalogNavigation: catalogNavigationData }}
                      isDarkMode={isDarkMode}
                      color={color}
                      isAem={isAem}
                      justify={catalogNavigation ? "space-evenly" : undefined}
                      selectedNavItem={selectedCatalogNavItem}
                      onChangeSelectedNavItem={handleSelectedNavItem}
                      onCategoryDrawerClose={handleCategoryDrawerClose}
                      disabled={isModalTrigger}
                      showBackToRH={
                        renderingFrom === "headerSwitcher"
                          ? showBackToRH
                          : false
                      }
                      backtoRHLabel={backtoRHLabel}
                      backToHomeLink={
                        backToLink ||
                        aemLogoLink ||
                        `https://${
                          brand === "TN"
                            ? RHTN + "."
                            : brand === "BC"
                            ? RHBC + "."
                            : brand === "OD"
                            ? RHOD + "."
                            : ""
                        }${env.REACT_APP_ATG_HOST_NAME}${prefix}`
                      }
                    />
                  )}
                </div>
              )}
            {isAem && !showShoppingCategories && (
              <div className="h-[16px]"></div>
            )}
          </div>
        </Toolbar>

        {/* World of RH Menu Drawer (both desktop and mobile) */}
        {(!processEnvServer ? mdUp : !mobile) && isWORHMenuOpen ? (
          <Fade in={isWORHMenuOpen} timeout={RHR_FADE_TIMEOUT_DURATION}>
            <div
              aria-modal
              role="dialog"
              style={{
                zIndex: theme.zIndex.appBar - 1,
                backgroundColor: theme.palette.common.white,
                display: isWORHMenuOpen ? "block" : "none"
              }}
            >
              <WORHMenuContent
                menuItems={
                  aemHamburgerNavItems
                    ? aemHamburgerNavItems
                    : worhMenu?.childCategories ?? []
                }
                bottomLinks={
                  aemFooterLinks
                    ? aemFooterLinks
                    : worhMenu
                    ? ((worhMenu as any).bottomLinks as NavigationElement[])
                    : []
                }
                onClose={handleWORHDrawerClose}
              />
            </div>
          </Fade>
        ) : (
          <DrawerComponent
            anchor="left"
            onClose={handleWORHDrawerClose}
            open={isWORHMenuOpen}
            scrollToTopEnable={false}
            classes={{
              root: classes.drawerRoot,
              paper: "overflow-visible h-full w-[300px] pb-[40px]"
            }}
          >
            {isAem && aemLogoXS && (
              <div className={classes.mobileLogoWrapper}>
                <a
                  id="container-rhr-header_mobile_logo-rhr"
                  href={
                    aemLogoLink ||
                    `https://${env.REACT_APP_ATG_HOST_NAME}${prefix}`
                  }
                  data-analytics-worhlogo="worh-logo"
                  style={isModalTrigger ? { pointerEvents: "none" } : undefined}
                >
                  <BreakpointAemLogo color={BLACK} />
                </a>
              </div>
            )}
            <MobileMenuContent
              catalogNavigationProps={{
                catalogNavigation: catalogNavigationData
              }}
              menuItems={
                aemHamburgerNavItems
                  ? aemHamburgerNavItems
                  : worhMenu?.childCategories ?? []
              }
              footerLinks={
                aemFooterLinks ? aemFooterLinks : worhMoblieFooterLinks || []
              }
              onCloseMenu={handleWORHDrawerClose}
              isAem={isAem}
              isWORHMenuOpen={isWORHMenuOpen}
            />
          </DrawerComponent>
        )}
      </AppBar>
    </>
  );
};

RHRHeaderContent.defaultProps = {
  styles: {
    root: {
      position: STATIC,
      background: PAGE_BG_GREY,
      color: BLACK
    }
  }
};

const RHRHC = memoize(RHRHeaderContent);

const RHRHeader: FC<RHRHeaderProps> = memoize(
  ({ RHRHeaderContentProps, ...rest }) => {
    // const nextgenPg =" useIsoCookies(["nextgenpg"])?.nextgenpg";
    //defaulting it to NG
    const nextgenPg = "true";
    const siteId = useSite();
    const isMobile = useMediaQuery<Theme>(theme =>
      theme.breakpoints.down("lg")
    );
    const env = useEnv();
    const prefix = useLocalization();
    const locale = useLocale();
    const countryCode = useCountry();
    const FEATURE_AEM_FOOTER = yn(env.FEATURE_AEM_FOOTER);
    const footerContentAuthor = rest.isInEditor
      ? useFetchModel(`/admin/footer-links`, false, false)
      : null;
    const hamburgerNavAuthor = rest.isInEditor
      ? useFetchModel(`/admin/hamburger-nav`, false, false)
      : null;
    const { pageContent } = usePageContentNoProxy();
    const hamburgerNav = rest.isInEditor
      ? hamburgerNavAuthor
      : pageContent
      ? pageContent.hamburgerNav
      : {};
    const footerLinks = rest.isInEditor
      ? footerContentAuthor
      : pageContent
      ? pageContent.footerLinks
      : {};

    const { data: { promoDisplayContent } = {} as Query } = useQuery<Query>(
      queryPromoDisplayContent,
      {
        variables: {
          context: PROMO_ABOVE_NAV,
          siteId
        },
        skip: RHRHeaderContentProps?.skipAboveNavPromoDisplay ?? true
      }
    );

    const { data: { catalogNavigation } = {} as Query, refetch } =
      useQuery<Query>(queryCatalogNavigation, {
        variables: {
          siteId,
          filters: "",
          mobile: false,
          locale,
          countryCode,
          nextgenPg: nextgenPg || "false"
        }
      });

    const worhMenu = hamburgerNav;
    useDidMountEffect(() => {
      refetch({
        siteId,
        locale
      });
    }, [siteId]);
    const worhBottomLinksData = useQuery<Query>(queryWORHMenu, {
      variables: {
        categoryId: WORH_BOTTOM_LINKS_CATEGORY_ID,
        locale
      },
      skip: isMobile
    });

    const worhMenuBottomLinks =
      FEATURE_AEM_FOOTER && footerLinks
        ? footerLinks
        : worhBottomLinksData?.data?.worhMenu?.childCategories;

    const AEMFooterContent = useMemo(() => {
      const linkSource = footerLinks ? footerLinks : pageContent;
      const linksObject = linkSource ? Object.keys(linkSource) : [];
      return linksObject.map(key => {
        const rowArray = linkSource[key]
          ? linkSource[key].items
            ? Array.isArray(linkSource[key].items)
              ? linkSource[key].items
              : Object.values(linkSource[key].items)
            : Array.isArray(linkSource[key])
            ? linkSource[key]
            : Object.values(linkSource[key])
          : [];
        return {
          displayName: (rowArray && rowArray[0] && rowArray[0].groupLabel
            ? rowArray[0].groupLabel
            : key
          ).toUpperCase(),
          id: key,
          linkType: "default",
          navImage: "",
          targetUrl: "",
          uxAttributes: null,
          childCategories: (linkSource &&
          linkSource[key] &&
          Array.isArray(linkSource[key])
            ? linkSource[key]
            : []
          ).map(({ text, url }) => ({
            displayName: text?.toUpperCase(),
            id: `${key}_${text}`,
            linkType: "default",
            navImage: "",
            targetUrl: url,
            uxAttributes: null,
            childCategories: null
          }))
        };
      });
    }, [pageContent]);

    const moblieFooterLinks = AEMFooterContent;

    const location = useLocation();
    const catalogNavigationData =
      RHRHeaderContentProps && RHRHeaderContentProps.catalogNavigation
        ? RHRHeaderContentProps.catalogNavigation
        : catalogNavigation;

    const CatalogNavigationPropsMemorized = useMemo(() => {
      return {
        catalogNavigation: {
          ...catalogNavigationData,
          childCategories: prefixTargetUrlsWithLocalization(
            cloneDeep(catalogNavigation?.childCategories ?? []),
            prefix
          )
        },
        ...RHRHeaderContentProps?.CatalogNavigationProps
      };

      return catalogNavigation;
    }, [
      RHRHeaderContentProps?.CatalogNavigationProps,
      catalogNavigation,
      prefix
    ]);

    const worhMenuMemorized = useMemo(() => {
      return {
        ...worhMenu,
        childCategories: prefixTargetUrlsWithLocalization(
          cloneDeep(worhMenu?.childCategories ?? []),
          prefix
        )
      };

      return worhMenu;
    }, [prefix, worhMenu]);

    return (
      <RHRHC
        {...RHRHeaderContentProps}
        aboveNavPromoDisplay={promoDisplayContent}
        CatalogNavigationProps={CatalogNavigationPropsMemorized}
        styles={RHRHeaderContentProps?.styles}
        worhMenu={worhMenuMemorized}
        worhMenuBottomLinks={worhMenuBottomLinks}
        worhMoblieFooterLinks={moblieFooterLinks}
        pagePath={location?.pathname}
        CatalogNavigationPropsMemorized={CatalogNavigationPropsMemorized}
        {...rest}
      />
    );
  }
);

export default RHRHeader;
