import React, { FC, useCallback } from "react";
import { ListItem, Fade, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useNavigationStylesByBrand } from "@RHCommerceDev/hooks/useTypographyStyles";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import he from "he";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import buildPath, { isForcedReloadPath } from "@RHCommerceDev/utils/buildPath";
import useParams from "@RHCommerceDev/hooks/useParams";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import memoize from "@RHCommerceDev/utils/memoize";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useStyles as useNavigationStyles } from "@RHCommerceDev/container-rhr-header/RHRCatalogNavigationDetails/style";
import { isSSRToggledCheck } from "@RHCommerceDev/utils/ssrHelpers";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";

export interface TerminalListItemProps {
  topCat?: any;
  parentCat?: any;
  childCategory: NavigationElement;
  onClick: (event: React.MouseEvent) => void;
  hoverStyle?: "underline" | "highlight";
}

export const TerminalListItem: FC<TerminalListItemProps> = ({
  topCat,
  parentCat,
  childCategory,
  onClick,
  hoverStyle
}) => {
  const theme = useTheme();
  const typographyStyles = useNavigationStylesByBrand({
    keys: ["desktopNavLink", "desktopNavLinkHighlight"]
  });
  const history = useHistory();
  const navClasses = useNavigationStyles();
  const { getResult } = isForcedReloadPath("");
  const { app } = useAppData();
  const prefix = useLocalization();
  const params = useParams({
    topCatId: "",
    categoryId: ""
  });
  const isSSRToggled = isSSRToggledCheck();
  const { setStorageValueWrapper } = useCookiesWithPermission();

  const onLinkClick = useCallback(
    (event: React.MouseEvent) => {
      onClick(event);
      analyticsLoader(a => {
        a.emitAnalyticsEvent(
          // @ts-ignore
          document.querySelector("#spa-root > *")!,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { remove: "analytics-navigatingFromSearch" }
        );
      });
      if (childCategory.targetUrl?.trim().indexOf("http") === 0) {
        window.location.href = childCategory.targetUrl;
      } else {
        const target = event.target as HTMLElement;
        const id = (target?.parentNode as HTMLElement)?.id;
        document?.body?.dispatchEvent(
          new CustomEvent("cta_click", {
            detail: {
              item: {
                isHeaderNavigation: true,
                topCat: topCat?.displayName,
                parentCat: parentCat?.displayName,
                cat: childCategory?.displayName,
                catURL: childCategory?.targetUrl,
                class: target?.className,
                id: target?.id || id
              }
            }
          })
        );
        const path = buildPath(prefix + childCategory.targetUrl, {
          sale: app.saleContextFilter,
          topCatId: topCat?.id,
          parentCatId: parentCat?.id,
          fromNav:
            childCategory.targetUrl.includes("new-results") ||
            childCategory.targetUrl.includes("results")
              ? "true"
              : null
        });

        if (isSSRToggled && getResult(path)) {
          window.location.href = path;
        } else {
          history.push(path);
        }

        // Setting up nav Path Through Sale.
        if (!processEnvServer) {
          const queryParams = location.search;
          if (queryParams?.includes("sale=true")) {
            localStorage.removeItem("analytics-navCatalog");
            localStorage.removeItem("fromNav");
            const navCatalog = {
              topCat: {
                id: topCat?.id,
                title: topCat?.displayName
              },
              parentCat: {
                id: parentCat?.id,
                title: parentCat?.displayName
              },
              cat: { id: childCategory?.id, title: childCategory?.displayName }
            };
            setStorageValueWrapper({ storageKey: "fromNav", value: "true" });
            setStorageValueWrapper({
              storageKey: "analytics-navCatalog",
              value: JSON.stringify(navCatalog)
            });
          }
          memoryStorage.setItem("fromNav", true);
        }
      }
    },
    [
      history,
      childCategory,
      app,
      topCat,
      parentCat,
      onClick,
      setStorageValueWrapper
    ]
  );

  return (
    <ListItem disableGutters onClick={onLinkClick}>
      <div
        style={{
          marginLeft: theme.typography.pxToRem(32),
          marginRight: theme.typography.pxToRem(32)
        }}
      >
        <Fade in appear timeout={500}>
          <div>
            <Typography
              className={clsx(
                navClasses.onHover,
                navClasses.extraLineHeight,

                {
                  [navClasses.selected]:
                    topCat?.id === params.topCatId &&
                    childCategory.id === params.categoryId
                },

                "terminal"
              )}
              variant="caption"
              data-analytics-category-id={childCategory.id}
              data-analytics-category-name={childCategory.displayName}
              data-analytics-parentcat-id={parentCat?.id}
              data-analytics-parentcat-name={parentCat?.displayName}
              data-analytics-topcat-id={topCat?.id}
              data-analytics-topcat-name={topCat?.displayName}
              data-navigation-item-typography="true"
              data-navigation-item-typography-id={childCategory.id}
              dangerouslySetInnerHTML={{
                __html: he.decode(
                  childCategory.displayName.replace(/<br>/g, "")
                )
              }}
            />
          </div>
        </Fade>
      </div>
    </ListItem>
  );
};

export default memoize(TerminalListItem);
