import { useApolloClient } from "@apollo/client";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import clsx from "clsx";
import HeaderSearchTextField from "@RHCommerceDev/component-header-search-text-field";
import RHLink from "@RHCommerceDev/component-rh-link";
import RHMenuDrawer from "@RHCommerceDev/component-rh-menu-drawer";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import { useStyles } from "container-rhr-search-field/styles";
import { formatDisplayName } from "@RHCommerceDev/container-rhr-search-field/utils";
import { queryTypeahead } from "@RHCommerceDev/graphql-client/queries/search";
import he from "he";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useEnv } from "hooks/useEnv";
import useIsoRedirect from "@RHCommerceDev/hooks/useIsoRedirect";
import { useLocalization } from "hooks/useLocalization";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useState from "@RHCommerceDev/hooks/useState";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import RHRSearchIcon from "@RHCommerceDev/icon-search-rhr";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";

import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useHistory } from "react-router";
import {
  COLLECTIONS,
  CUSTOMER_EXPERIENCE,
  PLEASE_TRY_A_NEW_SEARCH_OR_CONTACT,
  PRODUCTS,
  SEARCH,
  SEE_ALL,
  ZERO_RESULTS
} from "resources/search-results-resource.json";
import { memoryStorage } from "utils/analytics/storage";
import { TYPEAHEAD_DEBOUNCE_KEY, TYPEAHEAD_START } from "utils/constants";
import { formatSearchText, removeNttSearchParams } from "utils/formatSearchUrl";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import memoize from "utils/memoize";
import { codePattern } from "utils/regex";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { useSetSipId } from "@RHCommerceDev/hooks/atoms/useSIPID";
import yn from "yn";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";

const DISABLE_TYPEAHEAD = true;

export interface RHRSearchFieldProps {
  searchResultText?: string;
  isHeaderSearch?: boolean;
  isDarkMode?: boolean;
  backgroundColorRHMenu?: any;
  isFontWhiteColor?: boolean;
  color?: string;
  /* extra state */
  isRHRSearchField?: boolean;
  onClose?: () => void;
  disabled?: boolean;
  searchFieldLabel?: { placeHolder: string; buttonText: string };
}

export interface SearchDataProps {
  isRHRSearchField?: boolean;
  /** @ts-ignore */
  queryResults?: SearchAutoSuggestResponse;
  searchText?: string;
  handleLinkClicked?: () => void;
  _onClose?: () => void;
  formatDisplayName?: (
    displayName?: string,
    searchText?: string,
    parentCategoryName?: string | null
  ) => string;
}

export const SearchData: FC<SearchDataProps> = ({
  isRHRSearchField,
  queryResults,
  searchText = "",
  handleLinkClicked,
  _onClose,
  formatDisplayName
}) => {
  const classes = useStyles();
  const env = useEnv();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("lg"));
  const autoSuggestCategoryResults =
    queryResults?.categoryList?.categories ?? [];
  const autoSuggestProductResults = queryResults?.resultList?.records ?? [];
  const typographyClasses = useTypographyStyles({
    keys: [
      "searchAutoSuggestResult",
      "rhrSearchTitle",
      "rhrSearchAutoSuggestResult"
    ]
  });

  const handleClicked = useCallback(
    item => {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { searchTerm: item }
        )
      );
      _onClose();
      handleLinkClicked();
    },
    [_onClose, handleLinkClicked]
  );

  return (
    <>
      {isRHRSearchField && autoSuggestCategoryResults?.length > 0 && (
        <Typography
          className={clsx([typographyClasses.rhrSearchTitle, "text-#000000"])}
          variant="h4"
        >
          {COLLECTIONS}
        </Typography>
      )}
      {autoSuggestCategoryResults?.map(item => {
        const isExternalHost = item?.properties?.isCrossBrand;
        const navState = removeNttSearchParams(
          `${item.navigationState}&sale=false&Ntt2=${encodeURIComponent(
            searchText
          )}`
        );
        const path = `/search/${
          env?.FEATURE_URL_CHANGE ? searchText : "results.jsp"
        }${navState}`;
        return (
          <ListItem
            key={item.navigationState}
            disableGutters
            component={RHLink}
            href={isExternalHost ? item.host + path : ""}
            to={isExternalHost ? "" : path}
            style={isRHRSearchField ? { padding: 0 } : {}}
            onClick={() => handleClicked(item)}
          >
            <ListItemText
              style={isRHRSearchField && !smDown ? { margin: 0 } : {}}
              disableTypography
            >
              <Typography
                className={clsx([
                  !isRHRSearchField
                    ? typographyClasses.searchAutoSuggestResult
                    : typographyClasses.rhrSearchAutoSuggestResult,
                  "text-#000000"
                ])}
                variant="caption"
                dangerouslySetInnerHTML={{
                  __html: formatDisplayName(
                    item?.label,
                    searchText,
                    item?.properties?.parentCategoryName
                  )
                }}
              />
            </ListItemText>
          </ListItem>
        );
      })}
      {isRHRSearchField && autoSuggestProductResults.length > 0 && (
        <Typography
          className={clsx([
            `${typographyClasses.rhrSearchTitle} `,
            "text-#000000"
          ])}
          variant="h4"
        >
          {PRODUCTS}
        </Typography>
      )}
      {autoSuggestProductResults.map(item => (
        <ListItem
          key={item.product.repositoryId}
          disableGutters
          component={RHLink}
          style={isRHRSearchField ? { padding: 0 } : {}}
          to={
            item.product.repositoryId === "Membership-Enrollment"
              ? `/membership`
              : `/catalog/product/product.jsp?productId=${
                  item.product.repositoryId
                }&category=search&Ntt=${he.decode(
                  encodeURI(item.product.displayName)
                )}&Ntt2=${encodeURIComponent(searchText)}`
          }
          onClick={() => handleClicked(item)}
        >
          <ListItemText
            style={isRHRSearchField && !smDown ? { margin: 0 } : {}}
            disableTypography
          >
            <Typography
              className={clsx([
                !isRHRSearchField
                  ? typographyClasses.searchAutoSuggestResult
                  : typographyClasses.rhrSearchAutoSuggestResult,
                "text-#000000"
              ])}
              variant="caption"
              dangerouslySetInnerHTML={{
                __html: formatDisplayName(
                  item?.product?.displayName,
                  searchText
                )
              }}
            />
          </ListItemText>
        </ListItem>
      ))}
    </>
  );
};

export const ProductNotFound: FC<RHRSearchFieldProps> = ({
  isDarkMode = false
}) => {
  const typographyClasses = useTypographyStyles({
    keys: [
      "rhrSearchTitle",
      "rhrSearchAutoSuggestResult",
      "rhBaseCaption",
      "rhBaseH4"
    ]
  });
  const contactUsPath = "/customer-experience/contact-us";
  return (
    <>
      <Typography
        style={{ textTransform: "uppercase" }}
        className={clsx(
          typographyClasses.rhBaseH4,
          typographyClasses.rhrSearchTitle,
          "text-#000000"
        )}
      >
        {ZERO_RESULTS}
      </Typography>
      <Typography
        style={{ textTransform: "uppercase" }}
        className={clsx([typographyClasses.rhBaseCaption, "text-#000000"])}
      >
        {PLEASE_TRY_A_NEW_SEARCH_OR_CONTACT}

        <RHLink
          to={contactUsPath}
          data-testid={"customer-service-link"}
          style={{ display: "initial" }}
        >
          <Typography
            style={{ textTransform: "uppercase" }}
            className={clsx([
              typographyClasses.rhrSearchAutoSuggestResult,
              typographyClasses.rhBaseCaption,
              "uppercase ml-[5px] underline hover:no-underline",
              "text-#000000"
            ])}
          >
            {CUSTOMER_EXPERIENCE}
          </Typography>
        </RHLink>
      </Typography>
    </>
  );
};

export const RHRSearchField: FC<RHRSearchFieldProps> = ({
  searchResultText = "",
  isHeaderSearch = true,
  isRHRSearchField = false,
  isDarkMode = false,
  color = undefined,
  backgroundColorRHMenu = "",
  isFontWhiteColor = false,
  onClose,
  disabled,
  searchFieldLabel
}) => {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();
  const prefix = useLocalization();
  const { isConcierge } = useAppId();
  const typographyClasses = useTypographyStyles({
    keys: [
      "searchInput",
      "searchAutoSuggestResult",
      "rhrSearchTitle",
      "rhrSearchAutoSuggestResult",
      "rhBaseH4",
      "rhBaseCaption",
      "reimagineBody2"
    ]
  });
  const classes = useStyles();
  const { setStorageValueWrapper } = useCookiesWithPermission();

  const [searchText, setSearchText] = useState<string>("");
  /** @ts-ignore */
  const [queryResults, setQueryResults] = useState<SearchAutoSuggestResponse>(
    {}
  );
  const [showTypeAhead, setShowTypeAhead] = useState(false);
  const [open, setOpen] = useState(false);
  const { app, setApp } = useAppData();
  const env = useEnv();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const FEATURE_TAILWIND_COMPONENTS = yn(env?.FEATURE_TAILWIND_COMPONENTS);
  const {
    previousState: { country }
  } = useUserPreferences();

  const setSipId = useSetSipId();

  const INTLPrefix = useMemo(() => {
    return prefix;
  }, [prefix]);
  const searchPath = yn(env?.FEATURE_URL_CHANGE)
    ? formatSearchText(searchText)
    : `results.jsp?Ntt=${encodeURIComponent(searchText)}&Ns=product.sale%7C1`;

  useEffect(() => {
    if (!isHeaderSearch) {
      setSearchText(searchResultText);
    }
    // TODO: Fix eslint error and remove this comment block
    // React Hook useEffect has a missing dependency: 'isHeaderSearch'. Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultText]);

  useEffect(() => {
    if (!DISABLE_TYPEAHEAD) {
      if (searchText.length < TYPEAHEAD_START) {
        setQueryResults({});
        return;
      }

      const observable = client
        .watchQuery<Query>({
          query: queryTypeahead,
          variables: {
            ntt: searchText,
            country
          },
          context: {
            debounceKey: TYPEAHEAD_DEBOUNCE_KEY
          }
        })
        .subscribe(({ data }) => {
          /** @ts-ignore */
          setQueryResults(data.autoSuggest);
          /** @ts-ignore */
          if (data?.autoSuggest?.searchQueryId) {
            const fusionData = {
              /** @ts-ignore */
              fusion_id: data.autoSuggest.searchQueryId,
              /** @ts-ignore */
              fusion_filter: data.autoSuggest?.filterQueries
            };
            memoryStorage.setItem(
              "analytics-fusion-data",
              JSON.stringify(fusionData)
            );
          }
        });

      return () => {
        observable?.unsubscribe();
      };
    }
  }, [searchText, client]);

  const autoSuggestPromoResults = queryResults?.promo ?? [];

  const _onClose = useCallback(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
        {
          set: {
            name: "analytics-navigatingFromSearch",
            value: JSON.stringify(true)
          }
        }
      )
    );
    if (!isHeaderSearch) {
      setShowTypeAhead(false);
    }
    setSearchText("");
    setOpen(false);
    onClose?.();
  }, [isHeaderSearch]);

  const handleTypeahead = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "analytics-access",
          value: "typeahead"
        });
      }
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "analytics-access",
          value: "typeahead"
        });
      }
      setSearchText(event.target.value);
      if (!showTypeAhead && !isHeaderSearch) {
        setShowTypeAhead(true);
      }
    },
    [showTypeAhead, isHeaderSearch, setStorageValueWrapper]
  );

  const handleEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        !!searchText &&
        event.key === "Enter" &&
        !!codePattern.test(searchText)
      ) {
        // RESET SIP ID
        setSipId(null);

        if (!processEnvServer && !codePattern.test(searchText)) {
          sessionStorage.removeItem("pdpViewItem");
          sessionStorage.removeItem("rhrGridChoice");
          setStorageValueWrapper({
            storageKey: "analytics-access",
            value: "direct"
          });
        }
        useIsoRedirect(`${INTLPrefix}/search/${searchPath}`, "historyPush", {
          history
        });
        setApp({
          ...app,
          saleContextFilter: null
        });
        setSearchText("");
        setOpen(false);
        _onClose();
      }
    },

    [
      _onClose,
      app,
      setApp,
      history,
      searchText,
      INTLPrefix,
      setStorageValueWrapper
    ]
  );

  const handleLinkClicked = useCallback(() => {
    setApp({
      ...app,
      saleContextFilter: null
    });
    setSearchText("");
    setOpen(false);
    _onClose();
  }, [_onClose, app, setApp]);

  const _onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const handleSeeAllClick = useCallback(() => {
    // RESET SIP ID
    setSipId(null);

    if (!processEnvServer && !!codePattern.test(searchText)) {
      sessionStorage.removeItem("pdpViewItem");
      sessionStorage.removeItem("rhrGridChoice");
      setStorageValueWrapper({
        storageKey: "analytics-access",
        value: "direct"
      });
    }

    useIsoRedirect(`${INTLPrefix}/search/${searchPath}`, "historyPush", {
      history
    });

    _onClose();
  }, [INTLPrefix, searchText, setStorageValueWrapper]);

  const mdUp = useMediaQuery((theme: Theme) => theme?.breakpoints?.up("md"), {
    noSsr: !processEnvServer
  });
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const isTailwindComponentEnabled = useTailwindComponent();
  const req = getReqContext();
  let mobile = false;
  const userAgentSSR = req && req?.headers["user-agent"];
  if (userAgentSSR) {
    const { isMobile } = getSelectorsByUserAgent(userAgentSSR);
    mobile = !!isMobile;
  }
  const mdDown = useMediaQuery(theme.breakpoints.between(0, 992));
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(375));
  const xsmDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(610));
  const handleOpenSearchFieldDrawer = useCallback(
    () => ((mdDown || isConcierge) && !disabled ? setOpen(true) : ""),
    [mdDown]
  );

  const toCapitalCase = useCallback((placeHolderString: string) => {
    return (
      placeHolderString.charAt(0).toUpperCase() +
      placeHolderString.slice(1).toLowerCase()
    );
  }, []);

  return (
    <>
      {(!processEnvServer ? smUp : !mobile) ||
      (isTailwindComponentEnabled && smUp) ? (
        <HeaderSearchTextField
          isDarkMode={isDarkMode}
          color={color as any}
          onClick={() => (!smUp ? setOpen(true) : "")}
          openDrawer={() => (!smUp ? setOpen(true) : "")}
        />
      ) : (
        <IconButton
          disableRipple
          id="container-rhrSearchField_search-btn"
          onClick={handleOpenSearchFieldDrawer}
          className={FEATURE_TAILWIND_COMPONENTS ? "!p-0" : "!pl-0"}
          size="large"
        >
          <RHRSearchIcon
            className={"!w-[16px] !h-[16px] sm:!w-[26px] sm:!h-[26px]"}
            screenSize={xsmDown}
            style={{
              fontSize: undefined,
              color: color
                ? color
                : isDarkMode
                ? theme.palette.common.white
                : theme.palette.common.black
            }}
          />
        </IconButton>
      )}
      {open && (
        <RHMenuDrawer
          open={open}
          isDark={true}
          anchor="left"
          PaperProps={{
            className: `overflow-visible w-[75vw] sm:w-[66.66vw] md:w-[50vw] sm:[&_.MuiDialogContent-root]:p-[88px] xl:[&_.MuiDialogContent-root]:p-[128px]`
          }}
          ModalProps={{
            disableRestoreFocus: true
          }}
          className={classes.drawerRoot}
          onClose={() => {
            setOpen(false);
            onClose?.();
            setSearchText("");
          }}
          contentStyle={{
            paddingTop: "0px",
            overflowY: "scroll",
            background: backgroundColorRHMenu
          }}
          style={{
            pointerEvents: disabled ? "none" : undefined
          }}
        >
          <RHTextField
            id="site-search-input"
            variant="standard"
            margin="none"
            autoFocus
            disabled={disabled ? disabled : undefined}
            placeholder={searchFieldLabel?.placeHolder ?? toCapitalCase(SEARCH)}
            className={clsx([classes.searchBox])}
            fullWidth={isConcierge ? true : false}
            InputProps={{
              autoComplete: "off",
              value: searchText,
              classes: {
                underline: "!p-0"
              },
              className: clsx([
                classes.hoverSearch,
                isSSRToggledWithCSR
                  ? "pb-0 !bg-transparent"
                  : "pb-0 !bg-transparent",
                typographyClasses.reimagineBody2,
                "text-[13px] !font-[Baron Sans]",
                {
                  "!text-rh-black": !isDarkMode,
                  "!text-rh-white": isDarkMode
                }
              ]),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton disableRipple color="primary" className={"p-0"}>
                    <RHRSearchIcon
                      style={{
                        fontSize: 16,
                        color: theme.palette.common.black
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!!searchText && (
                    <IconButton
                      disableRipple
                      color="primary"
                      onClick={_onClearSearchText}
                    >
                      <RHCloseIcon
                        style={{
                          fontSize: 12,
                          color: isFontWhiteColor
                            ? theme.palette.common.white
                            : theme.palette.common.black
                        }}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            onChange={handleTypeahead}
            onKeyUp={handleEnterPressed}
          />
          {(isHeaderSearch || showTypeAhead) && (
            <>
              {!!searchText && DISABLE_TYPEAHEAD && (
                <div
                  style={{
                    margin: theme.spacing(4, 0),
                    zIndex: 10
                  }}
                >
                  <Button
                    id="container-rhr-search-field_see-all-btn"
                    style={{
                      minWidth: "168px",
                      minHeight: "48px"
                    }}
                    onClick={handleSeeAllClick}
                    variant="outlined"
                    color="secondary"
                    className={
                      "text-[#bdbdbd] border-1px solid rgba(189, 189, 189, 0.5)"
                    }
                  >
                    <Typography
                      variant="caption"
                      className={clsx([
                        "uppercase",
                        {
                          "!text-rh-black": !isDarkMode,
                          "!text-rh-white": isDarkMode
                        }
                      ])}
                    >
                      {searchFieldLabel?.buttonText ?? SEE_ALL}
                    </Typography>
                  </Button>
                </div>
              )}
              <div
                className={
                  isHeaderSearch ? classes.hoverSearch : classes.overlaySearch
                }
              >
                <List disablePadding style={{ zIndex: 10 }} dense>
                  {autoSuggestPromoResults.map(item => (
                    <ListItem
                      key={item.promotionDisplay_name_t}
                      disableGutters
                      component={RHLink}
                      to={`${item.promotionDisplay_imageUrl_s}`}
                      onClick={() => {
                        analyticsLoader(a =>
                          a.emitAnalyticsEvent(
                            document.querySelector(
                              "#spa-root > *"
                            )! as HTMLElement,
                            a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                            { searchTerm: item }
                          )
                        );
                        _onClose();
                      }}
                    >
                      <ListItemText disableTypography>
                        <Typography
                          color="primary"
                          className={clsx([
                            typographyClasses.searchAutoSuggestResult,
                            "text-#000000"
                          ])}
                          dangerouslySetInnerHTML={{
                            __html: formatDisplayName(
                              item.promotionDisplay_name_t ?? "",
                              searchText
                            )
                          }}
                        />
                      </ListItemText>
                    </ListItem>
                  ))}
                  {!searchText ? (
                    <></>
                  ) : queryResults?.categoryList?.categories?.length === 0 &&
                    queryResults?.resultList?.records?.length === 0 ? (
                    <ProductNotFound isDarkMode={isDarkMode} />
                  ) : (
                    <SearchData
                      formatDisplayName={formatDisplayName}
                      _onClose={_onClose}
                      handleLinkClicked={handleLinkClicked}
                      searchText={searchText}
                      isRHRSearchField={isRHRSearchField}
                      queryResults={queryResults}
                    />
                  )}
                </List>
                {!!searchText &&
                  (!!queryResults?.resultList?.records?.length ||
                    !!queryResults?.categoryList?.categories?.length) && (
                    <div
                      style={{
                        margin: theme.spacing(4, 0),
                        zIndex: 10
                      }}
                    >
                      <Button
                        id="container-rhr-search-field_see-all-btn"
                        style={{
                          minWidth: "168px",
                          minHeight: "48px"
                        }}
                        onClick={handleSeeAllClick}
                        variant="outlined"
                        color="secondary"
                        className={
                          "text-[#bdbdbd] border-1px solid rgba(189, 189, 189, 0.5)"
                        }
                      >
                        <Typography
                          variant="caption"
                          className={clsx(["text-#000000", "uppercase"])}
                        >
                          {searchFieldLabel?.buttonText ?? SEE_ALL}
                        </Typography>
                      </Button>
                    </div>
                  )}
              </div>
            </>
          )}
        </RHMenuDrawer>
      )}
    </>
  );
};

export default memoize(RHRSearchField);
